<ng-container *ngIf="(alerts$|async)?.length">

<div
  *ngFor="let alert of (alerts$|async)"
  [ngClass]="{
    'bg-red-100 border-red-200 text-red-700': alert.type === 'error',
    'bg-yellow-100 border-yellow-200 text-yellow-700': alert.type === 'warning',
    'bg-green-100 border-green-200 text-green-700': alert.type === 'success',
    'bg-blue-100 border-blue-200 text-blue-700': alert.type === 'info'
  }"
  class="px-4 py-3 border rounded relative mb-5" role="alert">
  <strong class="font-bold"> {{ getAlertTitle(alert.type) }} </strong>
  <span class="block sm:inline">{{ alert.message }}</span>
  <ul class="list-disc pl-5" *ngIf="alert?.errors">
    <li *ngFor="let error of alert.errors">{{ error[1] }}</li>
  </ul>

  <!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
  <!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
  <span class="absolute top-0 bottom-0 right-0 px-4 py-3" (click)="closeAlert(alert.id)">
      <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.914l-2.934 2.935a1 1 0 01-1.414-1.414l2.935-2.934-2.935-2.935a1 1 0 111.414-1.414L10 9.086l2.934-2.935a1 1 0 111.414 1.414L11.414 10l2.934 2.934a1 1 0 010 1.415z"/>
      </svg>
  </span>
  <!-- eslint-enable @angular-eslint/template/click-events-have-key-events -->
  <!-- eslint-enable @angular-eslint/template/interactive-supports-focus -->
</div>

</ng-container>
