import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom, Subscription } from 'rxjs';

import { LoadingComponent } from './components/loading/loading.component';
import { selectUser } from './store/selectors/user.selector';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, LoadingComponent],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public loaded = false;

  private initialLoad = true;

  private readonly userPending$ = this.store.select(selectUser);

  private readonly userSub!: Subscription;

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    const routerEvent = (await firstValueFrom(
      this.router.events,
    )) as NavigationStart;
    if (routerEvent?.url && String(routerEvent.url).startsWith('/auth')) {
      this.initialLoad = false;
    }

    this.userPending$.subscribe(({ pending }) => {
      if (!this.initialLoad && !this.loaded && !pending) {
        this.loaded = true;
      }

      if (this.initialLoad && pending) {
        this.initialLoad = false;
        this.userSub?.unsubscribe();
      }
    });
  }

  public ngOnDestroy(): void {
    this.userSub?.unsubscribe();
  }
}
