import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
} from 'rxjs/operators';

import { JOBS_AND_TESTS_PAGE_URL } from '../../pages/dashboard/jobs-and-tests/jobs-and-tests.page';
import { CognitoService } from '../../services/cognito.service';
import { createCustomAlert } from '../../utils/api';
import { CognitoActions } from '../actions/cognito.actions';
import { selectUrl } from '../selectors/router.selectors';

/**
 * Defines typical Cognito Exception codes that can be caught and handled.
 */
enum CognitoExceptions {
  /**
   * The user account exists but their password needs to be changed.
   *
   * This results when ProClubs users are first imported to Sense.
   */
  PASSWORD_RESET_REQUIRED_EXCEPTION = 'PasswordResetRequiredException',
  /**
   * The user account exists but has not been confirmed yet.
   */
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  /**
   * The user has tried to register with an existing account.
   */
  USER_EXISTS_EXCEPTION = 'UsernameExistsException',
  /**
   * The users credentials are incorrect.
   */
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
}

@Injectable()
export class CognitoEffects {
  public activateAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.activateAccount),
      switchMap((action) =>
        from(this.service.activate(action.credentials)).pipe(
          map(() => CognitoActions.activateAccountSuccess()),
          catchError((error) =>
            of(CognitoActions.activateAccountFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public currentSession$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.currentSession),
      switchMap(() =>
        from(this.service.currentSession()).pipe(
          map((data) => CognitoActions.currentSessionSuccess({ data })),
          catchError((error) =>
            of(CognitoActions.currentSessionFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public currentSessionSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.currentSessionSuccess),
        tap(() => CognitoActions.userAttributes()),
      ),

    { dispatch: false },
  );

  public forgotPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.forgotPassword),
      switchMap((request) =>
        from(this.service.resetPassword(request)).pipe(
          map((data) => CognitoActions.forgotPasswordSuccess({ data })),
          catchError((error) =>
            of(
              CognitoActions.forgotPasswordFailure({
                error: {
                  ...error,
                  username: request.username,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public forgotPasswordSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.forgotPasswordSuccess),
        tap(
          async () =>
            await this.router.navigate(['/auth/password-reset-success']),
        ),
      ),

    { dispatch: false },
  );

  public forgotPasswordFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.forgotPasswordFailure),
        tap(({ error }) => {
          const username = error?.username;

          if (
            error?.error?.message === 'User has not been confirmed' &&
            username
          ) {
            this.store.dispatch(CognitoActions.resendSignup({ username }));
            return createCustomAlert({
              message: `Activation email to ${username}.`,
            });
          } else {
            return createCustomAlert({
              message: `Password reset could not be sent.`,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  public resendSignup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.resendSignup),
      switchMap((action) =>
        from(this.service.resendSignUp(action.username)).pipe(
          mergeMap(() => [
            CognitoActions.resendSignupSuccess(),
            createCustomAlert({
              type: 'success',
              message: 'An activation link has been sent to your email',
            }),
          ]),
          catchError((error) =>
            of(CognitoActions.resendSignupFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.resetPassword),
      switchMap((request) =>
        from(this.service.resetPassword(request.credentials)).pipe(
          map(() =>
            CognitoActions.resetPasswordSuccess({
              data: {
                username: request.credentials.username,
                password: request.credentials.password,
              },
            }),
          ),
          catchError((error) =>
            of(CognitoActions.resetPasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public resetPasswordSuccess$: Observable<[Action, string]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.resetPasswordSuccess),
        concatLatestFrom(() => this.store.select(selectUrl)),
        tap(([{ data }]) => CognitoActions.signIn({ credentials: data })),
      ),
    { dispatch: false },
  );

  public resetPasswordFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.resetPasswordFailure),
        map(() =>
          createCustomAlert({
            message: 'Something went wrong. Please try again later.',
          }),
        ),
      ),
    { dispatch: false },
  );

  public resetPasswordWithCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.resetPasswordWithCode),
      switchMap((request) =>
        from(this.service.resetPasswordWithCode(request.credentials)).pipe(
          map(() =>
            CognitoActions.resetPasswordWithCodeSuccess({
              credentials: {
                username: request.credentials.username,
                password: request.credentials.newPassword,
              },
            }),
          ),
          catchError((error) =>
            of(CognitoActions.resetPasswordWithCodeFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public resetPasswordWithCodeSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.resetPasswordWithCodeSuccess),
        tap(({ credentials }) =>
          this.store.dispatch(CognitoActions.signIn({ credentials })),
        ),
      ),
    { dispatch: false },
  );

  public updatePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.updatePassword),
      switchMap((request) =>
        from(this.service.updatePassword(request.credentials)).pipe(
          map(() => CognitoActions.currentSession()),
          catchError((error) =>
            of(CognitoActions.updatePasswordFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public signIn$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.signIn),
      switchMap((request) =>
        from(this.service.signIn(request.credentials)).pipe(
          retry(2),
          map((data) => {
            // We need to re-parse the payload from Cognito
            data = JSON.parse(JSON.stringify(data));
            return CognitoActions.signInSuccess({ data });
          }),
          catchError((error) =>
            of(
              CognitoActions.signInFailure({
                error: {
                  ...error,
                  email: request.credentials.username,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public signInSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.signInSuccess),
        tap(
          async () =>
            await this.router.navigate([JOBS_AND_TESTS_PAGE_URL], {
              replaceUrl: true,
            }),
        ),
      ),
    { dispatch: false },
  );

  public signInFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.signInFailure),
      map(({ error }) => {
        const errorCode = error?.code;
        const email = error?.email;

        if (errorCode === 'NetworkError') {
          return createCustomAlert({
            message:
              'Unable to login. Please check your connection to the internet and try again.',
          });
        }

        if (errorCode === CognitoExceptions.NOT_AUTHORIZED_EXCEPTION) {
          return createCustomAlert({
            message:
              'Unable to login. Please check your credentials and try again.',
          });
        }

        if (errorCode === CognitoExceptions.PASSWORD_RESET_REQUIRED_EXCEPTION) {
          this.handlePasswordResetRequired(email);
        }

        if (
          errorCode === CognitoExceptions.USER_NOT_CONFIRMED_EXCEPTION &&
          email
        ) {
          return createCustomAlert({
            message: 'Unable to login. Registration not confirmed.',
          });
        }

        return createCustomAlert();
      }),
    ),
  );

  public signOut$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.signOut),
      switchMap(() =>
        from(this.service.signOut()).pipe(
          map(() => CognitoActions.signOutSuccess()),
          catchError((error) => of(CognitoActions.signOutFailure({ error }))),
        ),
      ),
    ),
  );

  public signOutSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.signOutSuccess),
        tap(async () => {
          // await this.storage.clear();
          return await this.router.navigate(['/auth/login'], {
            replaceUrl: true,
          });
        }),
      ),
    { dispatch: false },
  );

  public signUp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CognitoActions.signUp),
      switchMap((request) =>
        from(this.service.signUp(request.credentials)).pipe(
          map(() => CognitoActions.signUpSuccess()),
          catchError((error) =>
            of(
              CognitoActions.signUpFailure({
                error: {
                  ...error,
                  email: request.credentials.username,
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public signUpSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.signUpSuccess),
        tap(async () => {
          await this.router.navigate(['/auth/login']);
          this.store.dispatch(
            createCustomAlert({
              type: 'success',
              message: 'An activation link has been sent to your email',
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  public signUpFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoActions.signUpFailure),
        tap(async ({ error }) => {
          const email = error?.email;

          if (
            error?.name === CognitoExceptions.USER_EXISTS_EXCEPTION &&
            email
          ) {
            this.store.dispatch(
              createCustomAlert({ message: `You're already registered` }),
            );
            return;
          } else {
            this.store.dispatch(createCustomAlert());
            return;
          }
        }),
      ),
    { dispatch: false },
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly service: CognitoService,
    private readonly store: Store,
  ) {}

  private async handlePasswordResetRequired(email: string): Promise<void> {
    console.error('handlePasswordResetRequired: ', email);
  }
}
