import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import { OrganisationService } from '../../services/organisation.service';
import { handleHttpError } from '../../utils/api';
import { OrganisationUsersActions } from '../actions/organisation-users.actions';

@Injectable()
export class OrganisationUsersEffects {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.search),
      switchMap(({ organisationId, params, admin }) =>
        from(this.service.getUsers(organisationId, params.status, admin)).pipe(
          map(({ items }) =>
            OrganisationUsersActions.searchSuccess({ users: items }),
          ),
          catchError((error) =>
            of(OrganisationUsersActions.searchFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.get),
      switchMap(({ organisationId, userId, admin }) =>
        from(this.service.getUser(organisationId, userId, admin)).pipe(
          map((user) => OrganisationUsersActions.getSuccess({ user })),
          catchError((error) =>
            of(OrganisationUsersActions.getFailure({ error })),
          ),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUsersActions.getFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly service: OrganisationService,
  ) {}
}
