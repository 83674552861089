import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '../interfaces/api.interface';
import {
  Organisation,
  OrganisationCreate,
  OrganisationInviteUser,
  OrganisationSearchParams,
  OrganisationUpdate,
  OrganisationUser,
} from '../interfaces/organisation.interface';
import { UserStatus } from '../interfaces/status.interface';
import { UserRole } from '../interfaces/user.interface';
import { ApiService } from './api.service';

export interface AcceptInvitationPayload {
  readonly code: string;
}

export interface AcceptInvitationResponse {
  organisation: Organisation;
  userId: string;
  role: string;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  public constructor(private readonly api: ApiService) {}

  public search(
    params?: OrganisationSearchParams,
    admin = false,
  ): Observable<APIResponse<Organisation[]>> {
    let path = admin ? `admin/organisations` : `organisations`;

    if (params) {
      Object.entries(params).forEach(([key, value], index) => {
        return index === 0
          ? (path += `?${key}=${value}`)
          : (path += `&${key}=${value}`);
      });
    }

    return this.api.get<APIResponse<Organisation[]>>(path);
  }

  public get(id: string, admin = false): Observable<Organisation> {
    const path = admin ? `admin/organisations/${id}` : `organisations/${id}`;

    return this.api.get<Organisation>(path);
  }

  public create(payload: OrganisationCreate): Observable<Organisation> {
    return this.api.post<Organisation, OrganisationCreate>(
      `admin/organisations`,
      payload,
    );
  }

  public update(
    id: string,
    payload: OrganisationUpdate,
    admin = false,
  ): Observable<Organisation> {
    const path = admin ? `admin/organisations/${id}` : `organisations/${id}`;

    return this.api.put<Organisation, OrganisationUpdate>(path, payload);
  }

  public acceptInvitation(
    id: string,
    data: AcceptInvitationPayload,
  ): Observable<AcceptInvitationResponse> {
    return this.api.post<AcceptInvitationResponse, AcceptInvitationPayload>(
      `organisations/${id}/users/invite/accept`,
      data,
    );
  }

  public getAllUsers(id: string): Observable<APIResponse<OrganisationUser[]>> {
    return this.api.get(`organisations/${id}/users`);
  }

  // public getRoles(): Observable<any> {
  //   return this.api.get(`organisations/roles`);
  // }

  public getUsers(
    id: string,
    status?: UserStatus,
    admin = false,
  ): Observable<APIResponse<OrganisationUser[]>> {
    let statusQuery = '?status=';

    if (status) {
      statusQuery += status;
    }

    const path = admin
      ? `admin/organisations/${id}/users${statusQuery}`
      : `organisations/${id}/users${statusQuery}`;

    return this.api.get(path);
  }

  public getUser(
    orgId: string,
    userId: string,
    admin = false,
  ): Observable<OrganisationUser> {
    const path = admin
      ? `admin/organisations/${orgId}/users/${encodeURIComponent(userId)}`
      : `organisations/${orgId}/users/${encodeURIComponent(userId)}`;

    return this.api.get(path);
  }

  public inviteUser(
    id: string,
    user: OrganisationInviteUser,
    admin = false,
  ): Observable<OrganisationUser> {
    const path = admin
      ? `admin/organisations/${id}/users/invite`
      : `organisations/${id}/users/invite`;

    return this.api.post<OrganisationUser, OrganisationInviteUser>(path, user);
  }

  public removeUser(
    orgId: string,
    userId: string,
    admin = false,
  ): Observable<OrganisationUser> {
    const path = admin
      ? `admin/organisations/${orgId}/users/${encodeURIComponent(userId)}`
      : `organisations/${orgId}/users/${encodeURIComponent(userId)}`;

    return this.api.delete(path);
  }

  public resendInvite(
    id: string,
    data: { email: string },
  ): Observable<OrganisationUser> {
    return this.api.post<OrganisationUser, { email: string }>(
      `organisations/${id}/users/invite/resend`,
      data,
    );
  }

  public updateUserRole(
    organisationId: string,
    userId: string,
    role: { role: UserRole },
  ): Observable<OrganisationUser> {
    return this.api.patch<OrganisationUser, { role: UserRole }>(
      `organisations/${organisationId}/users/${encodeURIComponent(userId)}/role`,
      role,
    );
  }
}
