import { Routes } from '@angular/router';

import { adminGuard } from './guards/admin.guard';
import { authGuard } from './guards/auth.guard';
import { organisationGuard } from './guards/organisation.guard';
import { adminRoutes } from './pages/admin/admin.routes';
import { authRoutes } from './pages/auth/auth.routes';
import { AuthLayoutComponent } from './pages/auth/layout/auth-layout.component';
import { authenticatedRoutes } from './pages/authenticated.routes';
import { LayoutComponent } from './pages/layout/layout.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => authRoutes,
    title: 'Alpha Scientific',
  },
  {
    path: 'admin',
    canActivate: [authGuard, adminGuard],
    component: LayoutComponent,
    loadChildren: () => adminRoutes,
    title: 'Alpha Scientific',
  },
  {
    path: '',
    canActivate: [authGuard, organisationGuard],
    component: LayoutComponent,
    loadChildren: () => authenticatedRoutes,
    title: 'Alpha Scientific',
  },
  {
    path: '**',
    redirectTo: '',
  },
];
