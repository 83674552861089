import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  catchError,
  debounceTime,
  from,
  map,
  Observable,
  of,
  retry,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Job } from '../../interfaces/job.interface';
import { JobService } from '../../services/job.service';
import { handleHttpError } from '../../utils/api';
import { JobActions } from '../actions/job.actions';
import { selectSearchedParams } from '../selectors/job.selector';
import {
  selectActiveOrganisationId,
  selectUser,
} from '../selectors/user.selector';

@Injectable()
export class JobEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.get),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([{ id }, organisation]) =>
        from(this.service.get(organisation, id)).pipe(
          map((job) => JobActions.getSuccess({ job })),
          catchError((error) => of(JobActions.getFailure({ error }))),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.getFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.search),
      withLatestFrom(
        this.store.select(selectActiveOrganisationId),
        this.store.select(selectUser),
      ),
      debounceTime(700),
      switchMap(([{ params }, organisation, { user }]) => {
        const queryParams = { ...params };

        if (user?.activeOrganisation.role === 'USER') {
          queryParams.user = user.id;
        }

        return from(this.service.search(organisation, queryParams)).pipe(
          map(({ items }) => JobActions.searchSuccess({ jobs: items })),
          catchError((error) => of(JobActions.searchFailure({ error }))),
        );
      }),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.create),
      withLatestFrom(
        this.store.select(selectActiveOrganisationId),
        this.store.select(selectSearchedParams),
      ),
      switchMap(([action, organisationId, searchParams]) =>
        from(this.service.create(organisationId, action.payload)).pipe(
          retry(2),
          map((payload: Job) => {
            this.store.dispatch(JobActions.search({ params: searchParams }));

            return JobActions.createSuccess({ payload });
          }),
          catchError((error) =>
            of(
              JobActions.createFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public createFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.createFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.update),
      withLatestFrom(this.store.select(selectActiveOrganisationId)),
      switchMap(([action, organisationId]) =>
        from(
          this.service.update(organisationId, action.jobId, action.payload),
        ).pipe(
          map((payload) => JobActions.updateSuccess({ payload })),
          catchError((error) =>
            of(
              JobActions.updateFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobActions.updateFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly service: JobService,
    private readonly store: Store,
  ) {}
}
