import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';

import { JobsService } from '../../services/jobs.service';
import { handleHttpError } from '../../utils/api';
import { JobsActions } from '../actions/jobs.actions';

@Injectable()
export class JobsEffects {
  public search$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.search),
      switchMap(({ params }) =>
        from(this.service.search(params)).pipe(
          map(({ items }) => JobsActions.searchSuccess({ jobs: items })),
          catchError((error) => of(JobsActions.searchFailure({ error }))),
        ),
      ),
    ),
  );

  public searchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.searchFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.get),
      switchMap(({ organisationId, jobId }) =>
        from(this.service.get(organisationId, jobId)).pipe(
          map((job) => JobsActions.getSuccess({ job })),
          catchError((error) => of(JobsActions.getFailure({ error }))),
        ),
      ),
    ),
  );

  public getFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.getFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.update),
      switchMap((action) =>
        from(
          this.service.update(
            action.organisationId,
            action.jobId,
            action.payload,
          ),
        ).pipe(
          map((payload) => JobsActions.updateSuccess({ payload })),
          catchError((error) =>
            of(
              JobsActions.updateFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public updateFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.updateFailure),
      map((error) => handleHttpError(error)),
    ),
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly service: JobsService,
  ) {}
}
