{
  "name": "alpha-web-client",
  "version": "0.0.7",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "i18n": "ng extract-i18n --format=json",
    "build": "ng build",
    "build:qat": "ng build --configuration qat",
    "build:uat": "ng build --configuration uat",
    "watch": "ng build --watch --configuration qat",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "test": "ng test",
    "test:cov": "ng test --coverage",
    "prepare": "husky",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org behold --project alpha-web-client ./dist && sentry-cli --url https://sentry.behold.dev sourcemaps upload --org behold --project alpha-web-client ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.2.7",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.2.7",
    "@angular/router": "^18.2.7",
    "@ng-select/ng-select": "^13.9.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/router-store": "^18.0.2",
    "@ngrx/store": "^18.0.1",
    "@ngrx/store-devtools": "^18.0.2",
    "@sentry/angular": "^8.33.1",
    "angular-svg-icon": "^18.0.2",
    "aws-amplify": "^6.6.4",
    "date-fns": "^4.1.0",
    "js-datepicker": "^5.18.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.7.0",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-devkit/build-angular": "^18.2.7",
    "@angular/cli": "^18.2.7",
    "@angular/compiler-cli": "^18.1.1",
    "@angular/localize": "^18.2.7",
    "@eslint/js": "^9.7.0",
    "@sentry/cli": "^2.36.6",
    "@types/eslint__js": "^8.42.3",
    "@types/jest": "^29.5.13",
    "angular-eslint": "18.3.1",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.12.0",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "husky": "^9.1.6",
    "jest": "^29.7.0",
    "jest-junit": "^16.0.0",
    "lint-staged": "^15.2.10",
    "postcss": "^8.4.47",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.8",
    "tailwindcss": "^3.4.13",
    "typescript": "~5.5.2",
    "typescript-eslint": "8.8.0"
  }
}
